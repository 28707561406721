body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  /* background-color: #fafafa; */
}

* {
  scroll-behavior: smooth !important;
}

/* Scrollbar base styles */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #fff;
}

/* Scrollbar thumb styles */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 16px;
  border: 5px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
  border: 4px solid #fff;
  border-left: 0;
}

/* Hide scrollbar buttons */
::-webkit-scrollbar-button {
  display: none;
}
